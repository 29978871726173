// 以下に app/frontend/js 配下のスクリプトをimportする
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';

//scss import
import '@/stylesheets/admin.scss';
import 'hint.css';

//js import

// AdminLTE
import 'admin-lte';
import 'admin-lte/dist/css/adminlte.min.css';
import 'admin-lte/dist/js/adminlte.min.js';

// ActionText
import "trix";
import "@rails/actiontext";
Trix.config.lang.urlPlaceholder = "https://www.example.com（https部分も入力してください）";